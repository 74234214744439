<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';

// Stores
const alertService = appAlertStore();

// Computed
const isOpen = computed(() => alertService.getStatus());
</script>
<template>
  <TransitionRoot as="template" :show="isOpen">
    <Dialog class="relative z-10" @close="alertService.closeAlert()">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
            >
              <div class="sm:flex sm:items-start">
                <div
                  class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10"
                  :class="alertService.getAlert?.type === 'warning' ? 'bg-yellow-100' : 'bg-red-100'"
                >
                  <ui-icon
                    class="h-6 w-6"
                    :class="alertService.getAlert?.type === 'warning' ? 'text-yellow-600' : 'text-red-600'"
                    :name="alertService.getAlert?.type === 'warning' ? 'TriangleAlert' : 'TriangleAlert'"
                  />
                </div>
                <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">
                    {{ alertService.getAlert?.title }}
                  </DialogTitle>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">{{ alertService.getAlert?.message }}</p>
                  </div>
                </div>
              </div>
              <div class="flex items-center justify-end mt-4 space-x-2">
                <ui-button color="secondary" @click="alertService.closeAlert()">{{ $t('global.cancel') }}</ui-button>
                <ui-button
                  class="mr"
                  :left-icon="alertService.getAlert?.leftIcon"
                  :right-icon="alertService.getAlert?.rightIcon"
                  :color="alertService.getAlert?.type === 'warning' ? 'primary' : 'delete'"
                  @click="alertService.executeCallback()"
                >
                  {{ alertService.getAlert?.button ? alertService.getAlert?.button : $t('global.delete') }}
                </ui-button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
