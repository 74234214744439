import type { RouteRecordRaw } from 'vue-router';

export const productionRoutes: RouteRecordRaw[] = [
  {
    name: 'production-index',
    component: () => import('./../pages/index.vue'),
    path: '/tracking/productions',
    meta: {
      middleware: 'auth',
    },
  },
];
