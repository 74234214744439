import { createI18n } from 'vue-i18n';

import en from '@@/locales/en.json';
import fr from '@@/locales/fr.json';

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: 'fr',
  fallbackLocale: 'fr',
  messages: {
    en,
    fr,
  },
});

export default defineNuxtPlugin(({ vueApp }) => {
  vueApp.use(i18n);
  return {
    provide: {
      i18n,
    },
  };
});
