import { onAuthStateChanged, signInWithEmailAndPassword, SAMLAuthProvider, signInWithPopup, type UserCredential } from 'firebase/auth';
import { useI18n } from 'vue-i18n';
import * as Sentry from '@sentry/nuxt';

interface State {
  firebaseUser: null | UserCredential['user'];
  unsubscribe: null | (() => void);
  i18n: ReturnType<typeof useI18n>;
  accessToken: null | string;
}

export const firebaseStore = defineStore({
  id: 'firebaseStore',
  state: (): State => ({
    firebaseUser: null,
    unsubscribe: null,
    i18n: useI18n(),
    accessToken: null,
  }),
  getters: {
    getFirebaseUser(): UserCredential['user'] | null {
      return this.firebaseUser;
    },
    getAccessToken(): string | null {
      return this.accessToken;
    },
  },
  actions: {
    async initAuth() {
      const { fbAuth } = useFirebase();
      await new Promise((resolve) =>
        onAuthStateChanged(fbAuth, async (user: any) => {
          if (user) {
            const userId: string = user?.uid ? user?.uid : '';
            this.firebaseUser = user;
            this.accessToken = user.accessToken;
            resolve(userId);
          } else {
            this.firebaseUser = null;
            resolve(null);
          }
        }),
      );
    },
    async signIn(form: { email: string; password: string }) {
      const { fbAuth } = useFirebase();
      const { initApp } = globalStore();
      const { $posthog } = useNuxtApp();
      const router = useRouter();

      try {
        const data = await signInWithEmailAndPassword(fbAuth, form.email, form.password);
        this.firebaseUser = data.user;

        await initApp();

        const user = userStore().getCurrentUser;

        Sentry.setUser(user);
        $posthog.identify(user.id, {
          email: user.email,
          name: `${user.first_name} ${user.last_name}`,
          client_id: user.client.name,
        });
        $posthog.group('client', user.client.id.toString(), {
          client_id: user.client.id,
          name: user.client.name,
        });
        $posthog.capture('user_signed_in');

        router.push('/');
      } catch (error) {
        if (error.code === 'auth/user-not-found') {
          toastStore().showToast({
            type: 'error',
            title: this.i18n.t('global.error'),
            message: this.i18n.t('global.error_login_invalid_user'),
          });
        } else if (error.code === 'auth/invalid-credential') {
          toastStore().showToast({
            type: 'error',
            title: this.i18n.t('global.error'),
            message: this.i18n.t('global.error_login_invalid_password'),
          });
        } else {
          toastStore().showToast({
            type: 'error',
            title: this.i18n.t('global.error'),
            message: this.i18n.t('global.error_login_unknown'),
          });
        }
      }
    },
    async signInWithSSO(saml: string) {
      const { fbAuth } = useFirebase();
      const { initApp } = globalStore();
      const router = useRouter();
      const { $posthog } = useNuxtApp();
      try {
        const provider = new SAMLAuthProvider(saml);
        const data = await signInWithPopup(fbAuth, provider);
        this.firebaseUser = data.user;

        await initApp();

        const user = userStore().getCurrentUser;
        Sentry.setUser(user);
        $posthog.identify(user.id, {
          email: user.email,
          name: `${user.first_name} ${user.last_name}`,
          client_id: user.client.name,
        });
        $posthog.group('client', user.client.id.toString(), {
          client_id: user.client.id,
          name: user.client.name,
        });
        $posthog.capture('user_signed_in');

        router.push('/');
      } catch (error) {
        toastStore().showToast({
          type: 'error',
          title: this.i18n.t('global.error'),
          message: this.i18n.t('global.error_login_unknown'),
        });
        throw error;
      }
    },
    async logout() {
      const { fbAuth } = useFirebase();
      const { $posthog } = useNuxtApp();

      await fbAuth.signOut();
      Sentry.setUser(null);
      $posthog.reset();
    },
  },
});
