import type { RouteRecordRaw } from 'vue-router';

export const configurationRoutes: RouteRecordRaw[] = [
  {
    name: 'config-index',
    component: () => import('./../pages/index.vue'),
    path: '/configuration',
    meta: {
      middleware: 'auth',
    },
    children: [
      {
        name: 'config-alerts-index',
        component: () => import('./../pages/alerts/index.vue'),
        path: '/configuration/alerts',
      },
      {
        name: 'config-production-times-index',
        component: () => import('./../pages/productionHour/index.vue'),
        path: '/configuration/production-hours',
      },
      {
        name: 'config-contracts-index',
        component: () => import('./../pages/contract/index.vue'),
        path: '/configuration/contracts',
      },
      {
        name: 'config-areas-index',
        component: () => import('./../pages/area/index.vue'),
        path: '/configuration/areas',
      },
    ],
  },

  // Contracts
  {
    name: 'contract-create',
    component: () => import('./../pages/contract/manage.vue'),
    path: '/configuration/contract/create',
    meta: {
      middleware: 'auth',
    },
  },
  {
    name: 'contract-manage',
    component: () => import('./../pages/contract/manage.vue'),
    path: '/configuration/contract/edit/:id',
    meta: {
      middleware: 'auth',
    },
  },

  // Areas
  {
    name: 'area-create',
    component: () => import('./../pages/area/manage.vue'),
    path: '/configuration/areas/create',
  },
  {
    name: 'area-manage',
    component: () => import('./../pages/area/manage.vue'),
    path: '/configuration/areas/edit/:id',
  },
];
