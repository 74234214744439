import { gql } from '@apollo/client/core';

export const AREA_QUERY_UPDATE = gql`
  mutation UpdateArea($id: Int!, $object: areas_set_input!) {
    update_areas(where: { id: { _eq: $id } }, _set: $object) {
      affected_rows
    }
  }
`;

export const AREA_QUERY_CREATE = gql`
  mutation CreateArea($object: areas_insert_input!) {
    insert_areas_one(object: $object) {
      id
    }
  }
`;

export const AREAS_QUERY_LIST = gql`
  query AreasQuery($site_id: [Int!]!) {
    areas(where: { site_id: { _in: $site_id } }, order_by: { id: asc }) {
      id
      name
      parent_id
      area_type
      is_production_reporting_enabled
      is_fabrication_order_reporting_enabled
      fabrication_order_unit_id
      fabrication_order_unit {
        symbol
      }
      productions {
        id
      }
      production_unit_id
      production_unit {
        symbol
      }
      production_reporting_frequency
      site_id
      children {
        id
        name
      }
      parent {
        id
        name
      }
      fabrication_orders {
        id
        start
        end
        quantity
      }
      meters {
        id
        meter_type {
          energy_type
          step_seconds
        }
      }
      site {
        id
        name
        production_start_weekday
      }
    }
  }
`;

export const AREA_QUERY = gql`
  query AreaQuery($id: Int!) {
    areas(where: { id: { _eq: $id } }) {
      id
      children {
        id
        name
      }
      name
      parent_id
      parent {
        id
        name
      }
      area_type
      is_production_reporting_enabled
      is_fabrication_order_reporting_enabled
      site_id
      site {
        id
        name
      }
      fabrication_orders {
        id
        start
        end
        quantity
      }
      production_unit_id
      fabrication_order_unit_id
      fabrication_order_unit {
        symbol
      }
      production_unit {
        symbol
      }
      is_fabrication_order_reporting_enabled
    }
  }
`;

export const AREA_INSERT = gql`
  mutation InsertArea($object: areas_insert_input!) {
    insert_areas_one(object: $object) {
      site_id
      name
      id
    }
  }
`;

export const AREA_TYPES_QUERY_LIST = gql`
  query AreaTypesQueryList {
    area_types {
      value
    }
  }
`;
