import type { RouteRecordRaw } from 'vue-router';

export const componentLibraryRoutes: RouteRecordRaw[] = [
  {
    name: 'component-library-index',
    component: () => import('./../pages/index.vue'),
    path: '/component-library/',
    children: [
      {
        name: 'component-library-button',
        component: () => import('./../pages/button/index.vue'),
        path: 'button',
      },
      {
        name: 'component-library-input-date',
        component: () => import('../pages/input/date.vue'),
        path: 'input-date',
      },
      {
        name: 'component-library-charts',
        component: () => import('./../pages/charts/index.vue'),
        path: 'charts',
      },
    ],
  },
];
