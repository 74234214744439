import type { RouterConfig } from '@nuxt/schema';

import { exploreRoutes } from './app-modules/explore/router';
import { authRouter } from '@/app-modules/auth/router';
import { dashboardRouter } from '@/app-modules/dashboard/router';
import { incidentRoutes } from '@/app-modules/incident/router';
import { productRoutes } from '@/app-modules/product/router';
import { actionRoutes } from '@/app-modules/action/router';
import { configurationRoutes } from '@/app-modules/configuration/router';
import { adminRouter } from '@/app-modules/admin/router';
import { profileRoutes } from '@/app-modules/profile/router';
import { exploreWaterRoutes } from '@/app-modules/explore-water/router';
import { componentLibraryRoutes } from '@/app-modules/component-library/router';
import { productionRoutes } from '@/app-modules/production/router';

export default {
  routes: (_routes) => [
    ...authRouter,
    ...dashboardRouter,
    ...incidentRoutes,
    ...productRoutes,
    ...actionRoutes,
    ...exploreRoutes,
    ...configurationRoutes,
    ...adminRouter,
    ...profileRoutes,
    ...exploreWaterRoutes,
    ...componentLibraryRoutes,
    ...productionRoutes,
  ],
} satisfies RouterConfig;
