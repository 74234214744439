import { useI18n } from 'vue-i18n';
import { toastStore } from './toast';
import type { Alert } from '~/types/app';

interface State {
  alert: Alert | null;
  toast: any;
  i18n: any;
  status: boolean;
}

export const appAlertStore = defineStore({
  id: 'appAlert',
  state: (): State => ({
    status: false,
    alert: null,
    toast: toastStore(),
    i18n: useI18n(),
  }),
  getters: {
    getAlert(): Alert | null {
      return this.alert;
    },
  },
  actions: {
    getStatus() {
      return this.status;
    },
    showAlert(alert: Alert) {
      this.alert = alert;
      this.status = true;
    },
    closeAlert() {
      this.status = false;
      setTimeout(() => {
        this.alert = null;
      }, 200);
    },
    async executeCallback(): Promise<void> {
      try {
        if (this.alert) {
          await this.alert.callback();
          if (this.alert.successText) {
            this.toast.showToast({
              type: 'success',
              title: this.i18n.t('global.success'),
              message: this.alert.successText,
            });
          }
          this.closeAlert();
        }
      } catch (error) {
        this.toast.showToast({
          type: 'error',
          title: this.i18n.t('global.error'),
          message: this.i18n.t('global.message_error'),
        });
        throw error;
      }
    },
  },
});
