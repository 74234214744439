import { defineStore } from 'pinia';
import { provideApolloClient } from '@vue/apollo-composable';
import { cloneDeep } from '@apollo/client/utilities';
import { AREA_QUERY_CREATE, AREA_QUERY_UPDATE, AREA_TYPES_QUERY_LIST, AREAS_QUERY_LIST } from '~/graphql/area';
import type { Area, AreaTypes } from '~/types/area';

interface AreasState {
  areas: Area[];
  areaTypes: AreaTypes;
}

export const areaStore = defineStore({
  id: 'areaStore',
  state: (): AreasState => ({
    areas: [],
    areaTypes: [],
  }),
  getters: {
    getAreas(): Area[] {
      return this.areas.filter((area) => {
        return area.meters.length > 0;
      });
    },
    getArea: (state) => {
      return (area_id: number) => state.areas.find((area) => area.id === area_id);
    },
    getAreaTypes(): AreaTypes {
      return this.areaTypes;
    },
    getRootAreas(): Area[] {
      return this.getAreas.filter((area) => area.parent_id === null);
    },
    getAreaByMeterId: (state) => {
      return (meterId: number) => state.areas.find((area) => area.meters.some((meter) => meter.id === meterId));
    },
  },
  actions: {
    async loadAreas(): Promise<void> {
      const { apolloClient } = useApollo();
      provideApolloClient(apolloClient);

      // Load areas
      const areas: { data: { areas: Area[] } } = await apolloClient.query({
        query: AREAS_QUERY_LIST,
        variables: {
          site_id: globalStore().getSelectedSite,
        },
      });
      this.areas = cloneDeep(areas?.data?.areas);

      // Load area types
      const areaTypes: { data: { area_types: AreaTypes } } = await apolloClient.query({
        query: AREA_TYPES_QUERY_LIST,
      });
      this.areaTypes = cloneDeep(areaTypes?.data?.area_types);
    },
    async updateArea(options: { areaId: number; form: Partial<Area> }) {
      const { apolloClient } = useApollo();
      provideApolloClient(apolloClient);

      const object = {
        ...options.form,
        parent_id: options.form.parent_id === 0 ? null : options.form.parent_id,
        is_production_reporting_enabled: options.form.is_production_reporting_enabled,
        is_fabrication_order_reporting_enabled: options.form.is_fabrication_order_reporting_enabled,
      };

      await apolloClient.mutate({
        mutation: AREA_QUERY_UPDATE,
        variables: { id: options.areaId, object: object },
      });
      await this.loadAreas();
    },
    async createArea(options: { form: Partial<Area> }) {
      const { apolloClient } = useApollo();
      provideApolloClient(apolloClient);

      const object = {
        ...options.form,
        parent_id: options.form.parent_id === 0 ? null : options.form.parent_id,
        is_production_reporting_enabled: options.form.is_production_reporting_enabled,
        is_fabrication_order_reporting_enabled: options.form.is_fabrication_order_reporting_enabled,
      };

      await apolloClient.mutate({
        mutation: AREA_QUERY_CREATE,
        variables: { object: object },
      });
      await this.loadAreas();
    },
  },
});
