import type { RouteRecordRaw } from 'vue-router';

export const exploreRoutes: RouteRecordRaw[] = [
  {
    name: 'explore-index',
    component: () => import('./../pages/index.vue'),
    path: '/explore/global',
    meta: {
      middleware: 'auth',
    },
  },
  {
    name: 'explore-view',
    component: () => import('./../pages/view.vue'),
    path: '/explore/',
    meta: {
      middleware: 'auth',
    },
    children: [
      {
        name: 'explore-view-fabrication-orders',
        path: 'fabrication-orders/:id',
        component: () => import('./../components/tabs/EpiTab.vue'),
      },
      {
        name: 'explore-view-consumption',
        path: 'consumption/:id',
        component: () => import('./../components/tabs/ConsumptionTab.vue'),
      },
      {
        name: 'explore-view-off-production',
        path: 'off-production/:id',
        component: () => import('./../components/tabs/OffProductionTab.vue'),
      },
    ],
  },
];
